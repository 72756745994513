<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="width"
    :height="height"
    viewBox="0 0 20.446 5.112"
  >
    <path
      :style="iconStyle"
      d="M391.029,194.081a2.556,2.556,0,1,1-3.614,0,2.556,2.556,0,0,1,3.614,0"
      transform="translate(-371.331 -193.333)"
    />
    <path
      :style="iconStyle"
      d="M197.7,194.081a2.556,2.556,0,1,1-3.614,0,2.556,2.556,0,0,1,3.614,0"
      transform="translate(-185.666 -193.333)"
    />
    <path
      :style="iconStyle"
      d="M4.364,194.081a2.556,2.556,0,1,1-3.614,0,2.556,2.556,0,0,1,3.614,0"
      transform="translate(-0.001 -193.333)"
    />
  </svg>
</template>

<script>
export default {
  props: {
    height: {
      type: String,
      default: '15',
    },
    width: {
      type: String,
      default: '15',
    },
    color: {
      type: String,
      default: 'menu',
    },
  },
  computed: {
    /**
     * Icon Style
     * @param {void}
     * @returns {string} - Color
     * @description Assigns the specific color to the icon
     */
    iconStyle: function () {
      return {
        fill: ['$' + this.color, `var(--${this.color})`],
      }
    },
  },
}
</script>
